import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Location, DOCUMENT} from '@angular/common';

import { NavbarComponent } from './navbar/navbar.component';
import { Subscription, Observable } from 'rxjs';
import { BasketService } from 'src/app/core/services/basket.service';
import { CustomerInfo } from 'src/app/model/customer-info';
import { filter, map } from 'rxjs/operators';
import { ENavbarComponent } from 'src/app/layout/e-navbar/e-navbar.component';
import { SecurityService } from 'src/app/core/services/security.service';
import { OfferItem } from 'src/app/offers/model/offer-item-model';
import { ImageConfig } from 'src/app/model/api-config';
import { OffersService } from 'src/app/core/services/offers.service';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { LocaleService } from 'src/app/core/services/locale.service';
import { GlobalNoteService } from 'src/app/core/services/global-note.service';
import { Notification } from 'src/app/model/notification';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { IConfigurations } from '../core/models/settings.model';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-default-layout',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit {
    customerInfo!: CustomerInfo;
    imageConfig!:ImageConfig;

    footerBgCss = 'footer';
    footerBgColor = 'black';
    private _router!: Subscription;
    @ViewChild(NavbarComponent,{static: false}) navbar!: NavbarComponent;
    @ViewChild(ENavbarComponent,{static: false}) enavbar!: ENavbarComponent;

    IsAvailInvoiceCustomer:boolean = false;

    public offers!:Observable<OfferItem[]>;
    public globalNotes$!: Observable<Notification[]>;
    public showSlider:boolean | any=false;

    subSlideOptions={
        items: 1,
        dots: false,
        navigation: true,
        pagination:false,
        autoPlay:true,
        autoPlayTimeout:10000,
      }
      images:any;
      lowResImages:any;
      subSlideOptions2={
        items: 4,
        navigation: true,
        pagination:false
      }
      localeCode:string="";
      isHomePage:boolean=false;

    configurations:IConfigurations = {} as IConfigurations;
    isNotificationLoaded:boolean = false;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event:BeforeUnloadEvent) {

    this.securityService.getUser().then(data => {
      if(!data.expired)
      {
        event.preventDefault();
        event.returnValue =  false;
      }
    });
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    items:1,
    autoplay:true,
    autoplayTimeout:7000,
    autoplaySpeed:2000
  }

    constructor(private renderer: Renderer2,
        public router: Router,
        @Inject(DOCUMENT) private document: any,
        private element: ElementRef,
        public location: Location,
        public securityService:SecurityService,
        public basketService: BasketService,
        public offer_service:OffersService,
        public catalogService:CatalogService,
        public settingsService:SettingsService,
        public localeService:LocaleService,
        public globalNoteService:GlobalNoteService,
        public customerService:CustomerService) {
            this.configurations = this.localeService.GetConfigFromStore();
    }

    ngOnInit(): void {
        this.localeCode=this.localeService.code;
        this.router.url=='/home'?this.isHomePage=true:this.isHomePage=false;
        this.router.events.subscribe(event=>{
            this.router.url=='/home'?this.isHomePage=true:this.isHomePage=false;
        });

        this.settingsService.settings.subscribe(data=>{
            this.showSlider=data.SHOWOFFER
        })
            this.offers=this.offer_service.Offers;
            this.imageConfig = this.catalogService.getImageConfig();
            this.images=this.offers.pipe(map(item=>{
                return item.map(itm=>{
                    itm.promoNo=itm.promoNo!.toLowerCase();
                    itm.OfferImage=this.imageConfig.highResUri+'promo-'+itm.promoNo+'.jpg';
                    //return itm.OfferImage;
                    return itm;
                })
            }))
            this.lowResImages=  this.offers.pipe(map(item=>{
                return item.map(itm=>{
                    itm.promoNo=itm.promoNo!.toLowerCase();
                    itm.OfferImage=this.imageConfig.lowResUri+'promo-'+itm.promoNo+'.jpg';
                    //return itm.OfferImage;
                    return itm;
                })
            }));

        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                if (this.navbar !== undefined) {
                    this.navbar.sidebarClose();
                }
                else {
                    this.enavbar.sidebarClose();
                }
            });
            if(this.securityService.userRole == 'customer_admin')
            {
                this.customerService.getIsAvailInvoiceCustomer().subscribe(IsAvail =>{
                    this.IsAvailInvoiceCustomer = IsAvail;
                });
            }

            // this.basketService.basketData$.subscribe( Data => {
              // if(Data?.customerInfo?.customerPriceGroup == "NATIONAL")
              // {
              //   if(!this.configurations.defaultLayout.homeBanners.some(c => c == "Monsterkins 26072022"))
              //   {
              //     this.configurations.defaultLayout.homeBanners.push("Monsterkins 26072022");
              //   }
              // }

            // });

        if(this.configurations.defaultLayout.deptCodeBasedBanner.length > 0)
        {
          this.basketService.deptCode$.subscribe(cust_deptCode => {
            if (cust_deptCode == "14" || cust_deptCode == "57") {
              for(let i = 0;i < this.configurations.defaultLayout.deptCodeBasedBanner.length;i++)
              {
                if(!this.configurations.defaultLayout.homeBanners.some(c => c == this.configurations.defaultLayout.deptCodeBasedBanner[i]))
                {
                  this.configurations.defaultLayout.homeBanners.push(this.configurations.defaultLayout.deptCodeBasedBanner[i]);
                }
              }
            }
          })
        }
    }

    ngAfterViewInit() {

        var navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
        this.renderer.listen('window', 'scroll', (event) => {
            const number = window.scrollY;

            if (number > 70 || window.pageYOffset > 70) {
                navbar.classList.remove('navbar-transparent');
            }
            else {
                navbar.classList.add('navbar-transparent');
            }
        });
        var ua = window.navigator.userAgent;
        var trident = ua.indexOf('Trident/');
        var version ;
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        if (version) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('ie-background');

        }
    }
}
