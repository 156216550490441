import { Injectable } from '@angular/core';
import { ProductByPage } from '../model/product';
import { Category } from '../model/category';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ApiConfigService } from '../../core/services/api-config.service';
import { ImageConfig } from '../../model/api-config';
import { SecurityService } from '../../core/services/security.service';
import { ImageDownloadService } from '../../core/services/image-download.service';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { BasketService } from 'src/app/core/services/basket.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class CatalogService {
  @BlockUI() blockUI!: NgBlockUI;

  public zeroValueCategoryBehaviorSubject: BehaviorSubject<any[] | any> = new BehaviorSubject(this.initZeroCategory());
  zeroValueCategories: Observable<any[] | string> = this.zeroValueCategoryBehaviorSubject.asObservable();

  constructor(private http: HttpClient,
    private apiSvc: ApiConfigService,
    private securityService: SecurityService,
    private imageService: ImageDownloadService,
    private basketService: BasketService) {
  }


  getCategory(): Observable<Category[]> {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.product.categoryWithSubCategoryApi);
    return this.http.get<Category[]>(url, {
      headers: this.getHeader()
    }).pipe(map(response => {
      let categories: Category[] = [{ categoryCode: "", category: "All", b2BCategoryName: "All", displayOrder: 0,subCategoryList:[], showSubCategory: false },
      { categoryCode: "new", category: "New Arrivals", b2BCategoryName: "New Arrivals", displayOrder: 0,subCategoryList:[], showSubCategory: false }
      ];

      if (response != null && response.length > 0) {
        response.forEach(c => c.showSubCategory = false);
        //@ts-ignore
        categories.push(...response);
      }
      return categories;
    }));
  }

  getProducts(params: any): Observable<ProductByPage> {
    if (typeof (params.categorySelected) == 'undefined') {
      params.categorySelected = {} as any;
      params.categorySelected = "";
    }

    if (typeof (params.searchTerm) == 'undefined') {
      params.searchTerm = "";
    }

    if (typeof (params.sortOrder) != 'undefined') {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.product.catalogApi);
      let httpParam: HttpParams = new HttpParams()
        .set('pageNumber', params.page)
        .set('pageSize', params.pageSize)
        .set('category', params.categorySelected)
        .set('filter', params.searchTerm)
        .set('sortOrder', params.sortOrder)
        .set('ExcludeOutOfStock', params.ExcludeOutOfStock);
      return this.http.get<ProductByPage>(url, {
        headers: this.getHeader(),
        params: httpParam
      });
    }
    else {
      return of()
    }


  }

  getScannedProduct(params: any): Observable<ProductByPage> {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.product.scannedProductApi);
    let httpParams: HttpParams = new HttpParams()
      .set('filter', params.searchTerm);
    return this.http.get<ProductByPage>(url, {
      headers: this.getHeader(),
      params: httpParams
    })
  }

  getImageConfig(): ImageConfig {
    return this.apiSvc.apiConfig.image;
  }


  onDownloadImage(fileName: string) {
    this.imageService.downloadImage(fileName);
  }

  onAddToDownloadImageList(fileName: string) {
    this.imageService.addImageToDownloadList(fileName);
  }

  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader(this.basketService.getCustomerCode(), this.basketService.getPriceGroup());  //TS00370,this.basketService.getCustomerCode()
  }

  public setZeroValueCategory(categories?: any) {
    this.zeroValueCategoryBehaviorSubject.next(categories);
  }
  public getZeroValueCategories() {
    return this.zeroValueCategoryBehaviorSubject.getValue();
  }

  public initZeroCategory() {
    let categories: any = [];
    return categories;
  }

}

